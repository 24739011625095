import { Button, Card, Form, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { get, post, put } from "../../helper/api";

function EditGame({ setOpen,id }) {
  const [creating, setCreating] = useState(false);
  const[form]=Form.useForm()
  const [game,setGame]=useState()

  const getGame = async () => {
  
    const res = await get(`/games?gameId=${id}`);
    if (res?.success) {
      const g = res?.games?.length>0?res?.games[0]:{}

      setGame(g)
      form.setFieldsValue(g)
    }
    
  };

  const onSubmit = async (values) => {
    setCreating(true);
    const res = await put(`/games/${id}`, values);
    if (res?.success) {
      setOpen(false);
    
    }
    setCreating(false);
  };

  useEffect(()=>{
getGame()
  },[id])

  if(!game){
    return <Card loading></Card>
  }
  return (
    <Form layout="vertical"  initialValues={{
        active:false
    }} form={form} onFinish={onSubmit}>
      <Form.Item label="Name" name={"title"}>
        <Input />
      </Form.Item>
      <Form.Item label="Image Url" name={"image"}>
        <Input />
      </Form.Item>
      <Form.Item label="Min" name={"min"}>
        <Input />
      </Form.Item>
      <Form.Item label="Max" name={"max"}>
        <Input />
      </Form.Item>
      <Form.Item label="Active" name={"active"}>
        <Switch onChange={(e) => console.log(e)} />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={creating}>
        Submit
      </Button>
    </Form>
  );
}

export default EditGame;
