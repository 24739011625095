import axios from "axios";
import { SERVER_URL } from "../Configs";
import { message } from "antd";
const token = localStorage.getItem("ADMIN_TOKEN")

export const get = async (endpoint,query) => {
  try {
    const url = SERVER_URL + "/api/admin" + endpoint;
    const res = await axios.get(url,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{token:token}:{})
        },
        ...(query?{params:query}:{})
        
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const post = async (endpoint, data) => {
  try {
    const url = SERVER_URL + "/api/admin" + endpoint;
    const res = await axios.post(url, data,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{token:token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const uploadImg = async (file) => {
  try {
    const formData = new FormData
    formData.append("file",file)
    
    const url = SERVER_URL + "/api" + "/media/upload";
    const res = await axios.post(url, formData,{
        headers:{
            "Content-Type":"multipart/form-data",
            ...(token?{"token":token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
export const put = async (endpoint, data) => {
  try {
    const url = SERVER_URL + "/api/admin" + endpoint;
    const res = await axios.put(url, data,{
        headers:{
            "Content-Type":"application/json",
            ...(token?{token:token}:{})
        }
    });
    return res.data;
  } catch (error) {
    message.error(error.message);
  }
};
