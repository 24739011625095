import { Typography } from "antd";

export const getTitle = (name, action) => {
  return (
    <div className="flex items-center justify-between">
      <Typography.Text className="font-bold text-lg">{name}</Typography.Text>

      {action && action}
    </div>
  );
};
