import React, { useContext } from "react";
import Sidenav from "../components/Sidenav";
import Header from "../components/Header";
import AppContext from "../context/context";

function AppLayout({ children }) {
  const { sideBar, setSideBar } = useContext(AppContext);
  return (
    <div className="flex w-full h-screen relative bg-slate-50">
      <div
        className={`${
          sideBar
            ? "translate-x-0 lg:w-0"
            : "-translate-x-[100%] lg:w-full lg:-translate-x-0 "
        } fixed lg:flex p-0 w-full lg:p-4 h-full max-w-[300px] lg:sticky top-0 left-0 z-50 transition-all`}
      >
        <Sidenav />
      </div>
      <div className="flex-1 p-2 lg:p-3 w-full overflow-auto relative ">
        <Header />

        {children}
      </div>
    </div>
  );
}

export default AppLayout;
