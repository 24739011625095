import React, { useEffect, useState } from 'react'
import { get } from '../../helper/api'
import { Card } from 'antd'
import DataCard from './DataCard'
import { priceInRupees } from '../../helper/helper'
import CardParent from './CardParent'

function Dashboard() {
  const [data,setData ]= useState()
  const [loading,setLoading ]= useState(true)
  const getData = async()=>{
    const res = await get("/dashboard/metrics")
    if(res?.success){
      setData(res?.data)
    }
    setLoading(false)

  }

  useEffect(()=>{
getData()
  },[])


  return (
<div className='flex flex-col gap-10'>

<CardParent title={"Commission"}>
  <div className='grid  grid-cols-2 lg:grid-cols-3 gap-3'>
  <DataCard title={"Today AC"} value={priceInRupees(data?.commission?.todayACommission)} loading={loading}/>
  <DataCard title={"Admin"} value={priceInRupees(data?.commission?.admin)} loading={loading}/>
  <DataCard loading={loading} title={"Refer"} value={priceInRupees(data?.commission?.refer)}/>
  </div>
</CardParent>
<CardParent title={"Deposits"}>
  <div className='grid  grid-cols-2 lg:grid-cols-3 gap-3'>
  <DataCard loading={loading} title={"Today"} value={priceInRupees(data?.deposit?.today)} route={"/funds"}/>
  <DataCard loading={loading} title={"Upi Total"} value={priceInRupees(data?.deposit?.total)} route={"/funds"}/>
  <DataCard loading={loading} title={"Admin"} value={priceInRupees(data?.deposit?.totalAdmin)} route={"/funds"}/>
  </div>
</CardParent>


<CardParent title={"Battles"}>
  <div className='grid  grid-cols-2 lg:grid-cols-3 gap-3'>
  <DataCard loading={loading} title={"Open"} value={(data?.battle?.open)} route={"/battles"}/>
  <DataCard loading={loading} title={"Running"} value={(data?.battle?.running)} route={"/battles"}/>
  <DataCard loading={loading} title={"Complete"} value={(data?.battle?.complete)} route={"/battles"}/>
  <DataCard loading={loading} title={"Pending"} value={(data?.battle?.pending)} route={"/battles"}/>
  </div>
</CardParent>



<CardParent title={"Withdraws"}>
  <div className='grid  grid-cols-2 lg:grid-cols-3 gap-3'>
  <DataCard loading={loading} title={"Pending"} value={priceInRupees(data?.withdraw?.pending)} route={"/withdraws"}/>
  <DataCard loading={loading} title={"Total"} value={priceInRupees(data?.withdraw?.total)} route={"/withdraws"}/>
  </div>
</CardParent>

<CardParent title={"User Balance"}>
  <div className='grid  grid-cols-2 lg:grid-cols-3 gap-3'>

  <DataCard loading={loading} title={"Total"} value={priceInRupees(data?.balance)}/>
  </div>
</CardParent>









  
</div>
  )
}

export default Dashboard