import { Button, Form, Input, Switch, Upload, message } from "antd";
import React, { useState } from "react";
import { post, uploadImg } from "../../helper/api";
import { UploadOutlined } from "@ant-design/icons";
import FormItemLabel from "antd/es/form/FormItemLabel";
function CreateGame({ setOpen }) {
  const [creating, setCreating] = useState(false);
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();

  const handleImageChange = (e) => {
    try {
      const img = e.target.files[0];
      setFile(img);

    } catch (error) {
      message.error(error.message);
    }
  };

  const onSubmit = async (values) => {
    setCreating(true);
    if (file) {
      const res = await uploadImg(file);
      if (res?.success) {
        values["image"] = res?.url;
      }
    }
    const res = await post("/games/create", values);
    if (res?.success) {
      setOpen(false);
      form.resetFields();
    }
    setCreating(false);
  };
  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        active: false,
      }}
      form={form}
    >
      <Form.Item label="Name" name={"title"}>
        <Input />
      </Form.Item>
      <div className="grid grid-cols-2 items-center gap-4">
        <Form.Item label="Image Url" name={"image"}>
          <Input />
        </Form.Item>
        <FormItemLabel htmlFor="file"  label={file?file?.name :"Upload"} />
        <input type="file" style={{display:"none"}} id="file" onChange={handleImageChange} />
      </div>

      <Form.Item label="Min" name={"min"}>
        <Input />
      </Form.Item>
      <Form.Item label="Max" name={"max"}>
        <Input />
      </Form.Item>
      <Form.Item label="Active" name={"active"}>
        <Switch />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={creating}>
        Submit
      </Button>
    </Form>
  );
}

export default CreateGame;
