import React, { useEffect, useState } from "react";
import { FUND_STATUS } from "../../helper/constants";
import { Button, Card, Image, Table, Typography, message } from "antd";
import { get, put } from "../../helper/api";
import moment from "moment";
import { Link } from "react-router-dom";

const tabList = [
  { label: "Pending", key: FUND_STATUS.pending },
  { label: "Success", key: FUND_STATUS.success },
  { label: "Failed", key: FUND_STATUS.failed },
  { label: "Admin", key: "dpbyadmin" },
];

function FundRequests() {
  const [data, setData] = useState();
  const [status, setStatus] = useState(FUND_STATUS.pending);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Mode", dataIndex: "mode", key: "mode" },
    // { title: "Proof", dataIndex: "proof", key: "proof" },
    { title: "DateTime", dataIndex: "datetime", key: "datetime" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Proof", dataIndex: "proof", key: "proof" },
    ...(status === "dpbyadmin"
      ? [{ title: "Txn For", dataIndex: "txnFor", key: "txnFor" }]
      : []),
    ...(status === FUND_STATUS.pending
      ? [{ title: "Actions", dataIndex: "action", key: "action" }]
      : []),
  ];

  const updateStatus = async (id, status) => {
    message.open({
      type: "loading",
      key: id,
      content: `Updating status`,
    });
    const res = await put(`/funds/update-status/${id}`, { status });
    if (res?.success) {
      message.open({
        type: "success",
        key: id,
        content: res?.message,
      });
      getData();
    } else {
      message.open({
        type: "error",
        key: id,
        content: res?.messages,
      });
    }
  };

  const getData = async () => {
    setLoading(true);
    var payload = {};
    if (status === "dpbyadmin") {
      payload = {
        label: "Deposit by admin",
        page,
        limit,
      };
    } else {
      payload = { status, page, limit };
    }

    const res = await get(`/funds`, payload);
    if (res?.success) {
      setTotalData(res?.totalPages);
      setData(
        res?.funds?.map((f) => {
          return {
            ...f,
            name: <Link to={`/users/${f?.user?._id}`}>{f?.user?.name}</Link>,
            proof: (
             f?.user_proof? <Image
                src={f?.user_proof}
                width={50}
                height={60}
                className="object-cover rounded"
              />:"-"
            ),
            datetime: (
              <Typography.Text type="secondary" className="text-xs">
                {moment(f?.createdAt).format("DD-MM-YYYY, hh:mm a")}
              </Typography.Text>
            ),
            action: f?.status === "pending" && (
              <div className="flex flex-col gap-2">
                {f?.mode === "gateway" ? (
                  <Button
                    className="bg-green-500 text-white"
                    onClick={() => console.log("verify")}
                  >
                    Verify
                  </Button>
                ) : (
                  <>
                    <Button
                      className="bg-green-500 text-white"
                      onClick={() => updateStatus(f?._id,FUND_STATUS.success)}
                    >
                      Approve
                    </Button>
                    <Button
                      className="bg-red-500 text-white"
                      onClick={() => updateStatus(f?._id, FUND_STATUS.failed)}
                    >
                      Decline
                    </Button>
                  </>
                )}
              </div>
            ),
          };
        })
      );
    }
  };

  useEffect(() => {
    getData();
  }, [status]);
  return (
    <Card title="Funds" tabList={tabList} onTabChange={(e) => setStatus(e)}>
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            onChange: (e) => setPage(e),
            pageSize: limit,
            total: totalData,
          }}
        />
      </div>
    </Card>
  );
}

export default FundRequests;
