import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, put } from "../../helper/api";
import Loader from "../../components/Loader";
import { Button, Card, Popconfirm, Select, Typography, message } from "antd";
import { BsLightningChargeFill } from "react-icons/bs";
import { BATTLE_STATUS } from "../../helper/constants";
import { cancelBattle } from "../../svc/battle.svc";
import { getTitle } from "../../helper/comp";
import moment from "moment";
import { DateTimeFormate } from "../../helper/helper";

function OverView() {
  const { id } = useParams();
  const [battle, setBattle] = useState();
  const [cancelling, setCancelling] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [winner, setWinner] = useState();

  const navigate = useNavigate();
  const redirect = (id) => {
    navigate(`/users/${id}`);
  };

  const getBattle = async () => {
    const res = await get(`/battles?id=${id}`);
    if (res?.success) {
      const b = res?.battles?.length > 0 && res?.battles[0];
      if (b) {
        setBattle(b);
      }
    }
  };
  useEffect(() => {
    getBattle();
  }, []);

  const onBattleCancel = async () => {
    setCancelling(true);
    const res = await cancelBattle(id);
    if (res?.success) {
      message.success(res?.message);
      getBattle();
    } else {
      message.error(res?.message);
    }
    setCancelling(false);
  };

  const onUpdate = async () => {
    if (!winner) {
      return message.error("Select Winner");
    }
    setUpdating(true);
    const res = await put(`/battles/update-battle/${id}`, { winner });
    if (res?.success) {
      message.success(res?.message);
      getBattle();
    } else {
      message.error(res?.message);
    }
    setUpdating(false);
  };

  if (!battle) {
    return <Loader />;
  }
  return (
    <div>
      <Card title={`Id:-${battle?._id}`}>
        <div className="grid  grid-cols-3 ">
          <div className="flex flex-col items-center gap-2">
            <img
              src="https://cdn2.vectorstock.com/i/1000x1000/18/11/man-profile-cartoon-vector-19491811.jpg"
              alt=""
              className="w-[70px] h-[70px] rounded-full"
            />
            <Typography.Text>{battle?.createrId}</Typography.Text>
          </div>
          <div className="flex flex-col gap-2 items-center ">
            <BsLightningChargeFill className="text-red-600 text-3xl lg:text-5xl" />
            <Typography.Text className="font-bold text-red-500">
              V/S
            </Typography.Text>
          </div>
          <div className="flex flex-col items-center gap-2">
            <img
              src="https://cdn2.vectorstock.com/i/1000x1000/18/11/man-profile-cartoon-vector-19491811.jpg"
              alt=""
              className="w-[70px] h-[70px] rounded-full"
            />
            <Typography.Text>
              {battle?.joinerId || "Waiting for joiner.."}
            </Typography.Text>
          </div>
        </div>
        {battle?.status === BATTLE_STATUS.pending ||
        battle?.status === BATTLE_STATUS.open ||
        battle?.status === BATTLE_STATUS.running ? (
          <div className="flex flex-col gap-2 mt-4">
            <Typography.Text>Actions</Typography.Text>
            <div className="grid grid-cols-2 gap-2 max-w-[400px]">
              {battle?.status === BATTLE_STATUS.pending ||
              battle?.status === BATTLE_STATUS.running ? (
                <>
                  <Select
                    options={[
                      { value: battle?.createrId, label: battle?.createrId },
                      { value: battle?.joinerId, label: battle?.joinerId },
                    ]}
                    placeholder="Select Winner"
                    value={winner}
                    onChange={(e) => setWinner(e)}
                  />
                  <Button
                    className="bg-blue-500 text-white"
                    onClick={onUpdate}
                    loading={updating}
                    disabled={updating}
                  >
                    Submit
                  </Button>
                </>
              ) : null}
              <Popconfirm
                onConfirm={onBattleCancel}
                title="Cancel"
                description="Are you sure to cancel battle?"
                placement="bottomLeft"
              >
                <Button
                  className="bg-red-500 text-white mt-4"
                  loading={cancelling}
                  disabled={cancelling}
                >
                  Cancel Battle
                </Button>
              </Popconfirm>
            </div>
          </div>
        ) : null}
      </Card>
      <Card title={"Battle Info"} className="mt-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography.Text>Status</Typography.Text>
            <Typography.Text>{battle?.status}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>RoomCode</Typography.Text>
            <Typography.Text>{battle?.roomCode}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Amount</Typography.Text>
            <Typography.Text>{battle?.amount}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Prize</Typography.Text>
            <Typography.Text>{battle?.prize}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Admin Commission</Typography.Text>
            <Typography.Text>{battle?.admin_commission}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Refer Commission</Typography.Text>
            <Typography.Text>{battle?.refer_commission}</Typography.Text>
          </div>
          {battle?.winner && (
            <div className="flex items-center justify-between">
              <Typography.Text>Winner</Typography.Text>
              <Typography.Text>{battle?.winner}</Typography.Text>
            </div>
          )}
        </div>
      </Card>
      <div className="mt-2 grid  grid-cols-1 lg:grid-cols-2 gap-2">
        <Card
          title={getTitle(
            "Creater",
            <Button onClick={() => redirect(battle?.creater?._id)}>View</Button>
          )}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <Typography.Text>Id</Typography.Text>
              <Typography.Text>{battle?.creater?.key_id}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Name</Typography.Text>
              <Typography.Text>{battle?.creater?.name}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Mobile</Typography.Text>
              <Typography.Text>{battle?.creater?.mobile}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Cash</Typography.Text>
              <Typography.Text>
                {battle?.creater?.cash?.toFixed(1)}
              </Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Win</Typography.Text>
              <Typography.Text>
                {battle?.creater?.win?.toFixed(1)}
              </Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Response</Typography.Text>
              <Typography.Text>{battle?.createrResponse}</Typography.Text>
            </div>
            {battle?.createrResponseTime&&<div className="flex items-center justify-between">
              <Typography.Text>Response Time</Typography.Text>
              <Typography.Text>{moment(battle?.createrResponseTime).format(DateTimeFormate)}</Typography.Text>
            </div>}
            {battle?.createrResponse === "win" && (
              <div className="flex items-center justify-between">
                <Typography.Text>SS</Typography.Text>
                <a href={battle?.creater_win_proof} target="_blank">
                  Click to view
                </a>
              </div>
            )}
          </div>
        </Card>
        <Card
          title={getTitle(
            "Joiner",
            <Button onClick={() => redirect(battle?.joiner?._id)}>View</Button>
          )}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <Typography.Text>Id</Typography.Text>
              <Typography.Text>{battle?.joiner?.key_id}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Name</Typography.Text>
              <Typography.Text>{battle?.joiner?.name}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Mobile</Typography.Text>
              <Typography.Text>{battle?.joiner?.mobile}</Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Cash</Typography.Text>
              <Typography.Text>
                {battle?.joiner?.cash?.toFixed(1)}
              </Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Win</Typography.Text>
              <Typography.Text>
                {battle?.joiner?.win?.toFixed(1)}
              </Typography.Text>
            </div>
            <div className="flex items-center justify-between">
              <Typography.Text>Response</Typography.Text>
              <Typography.Text>{battle?.joinerResponse}</Typography.Text>
            </div>
            {battle?.joinerResponseTime&&<div className="flex items-center justify-between">
              <Typography.Text>Response Time</Typography.Text>
              <Typography.Text>{moment(battle?.joinerResponseTime).format(DateTimeFormate)}</Typography.Text>
            </div>}
            {battle?.joinerResponse === "win" && (
              <div className="flex items-center justify-between">
                <Typography.Text>SS</Typography.Text>
                <a href={battle?.joiner_win_proof} target="_blank">
                  Click To View
                </a>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default OverView;
