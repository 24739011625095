import { Spin } from "antd";
import React from "react";

function Loader() {
  return (
    <div className="w-full h-full   bg-slate-50 z-10 flex items-center justify-center">
      <div className=" flex flex-col items-center gap-2">
        <Spin />
        <p className="text-sm">Loading..</p>
      </div>
    </div>
  );
}

export default Loader;
