import React, { useContext } from "react";
import { RiMenuUnfoldLine } from "react-icons/ri";
import AppContext from "../context/context";

function Header() {
  const { sideBar, setSideBar } = useContext(AppContext);

  return (
    <div className="  w-scree lg:w-full sticky top-0 left-0  mb-2 z-40 ">
      <div className="bg-white shadow w-full p-4 rounded flex items-center justify-between">
        <div className="flex items-center gap-2 px-2 lg:px-4">
          <RiMenuUnfoldLine size={30} onClick={() => setSideBar(!sideBar)} />
          <b>Admin panal</b>
        </div>
      </div>
    </div>
  );
}

export default Header;
