import { Typography } from 'antd'
import React from 'react'

function CardParent({title,children}) {
  return (
    <div className='flex flex-col gap-2'>
        <Typography.Text className='text-lg font-bold'>{title}</Typography.Text>

        {children}

    </div>
  )
}

export default CardParent