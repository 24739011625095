import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../helper/api";
import { Button, Card, Image, Input, Switch, Tag, Typography, message } from "antd";
import Loader from "../../components/Loader";
import moment from "moment";
import TopUpUser from "./TopUpUser";
import WalletHistory from "./WalletHistory";
import BalanceDedcut from "./BalanceDedcut";
import { getTitle } from "../../helper/comp";
import { USER_STATUS } from "../../helper/constants";
import { FaCheckCircle } from "react-icons/fa";

function UserOverView() {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [kyc, setKyc] = useState();
  const [referBy,setReferBy]=useState()

  const getData = async () => {
    const res = await get(`/users?id=${id}`);
    if (res?.success) {
      const u = res?.users?.length > 0 && res?.users[0];
      setReferBy(u?.refferBy)
      setUser(u);
      setKyc(res?.kyc)
    }
  };
  const updateUser = async (vals) => {
    const res = await put(`/users/update-user?id=${id}`, vals);
    if (res?.success) {
      message.success(res?.message);
      getData();
    } else {
      message.error(res?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <Card
        title={getTitle(
          user?.name,
          <Tag
            color={user?.status === USER_STATUS.active ? "green" : "red"}
            onClick={() =>
              updateUser({
                status:
                  user?.status === USER_STATUS.active
                    ? USER_STATUS.blocked
                    : user?.status === USER_STATUS.blocked
                    ? USER_STATUS?.active
                    : "",
              })
            }
          >
            {user?.status}
          </Tag>
        )}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography.Text>Id</Typography.Text>
            <Typography.Text>{user?.key_id}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Name</Typography.Text>
            <Typography.Text>{user?.name}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Mobile</Typography.Text>
            <Typography.Text>{user?.mobile}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Otp</Typography.Text>
            <Typography.Text>{user?.otp}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Win</Typography.Text>
            <Typography.Text>{user?.win}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Cash</Typography.Text>
            <Typography.Text>{user?.cash}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>Total Balance</Typography.Text>
            <Typography.Text>{user?.cash + user?.win}</Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>DOJ</Typography.Text>
            <Typography.Text>
              {moment(user?.createdAt).format("DD-MM-YYYY, hh:mm a")}
            </Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text>ReferBy</Typography.Text>
            <div className="flex gap-2 flex-col items-end ">
         <Input className="max-w-[100px]" value={referBy} onChange={(e)=>setReferBy(e.target.value?.toUpperCase())}/>
         {referBy!==user?.refferBy&&<Tag className="cursor-pointer " onClick={()=>updateUser({refferBy:referBy})}>Save</Tag>}
         {/* <FaCheckCircle/> */}
            </div>
          </div>
        </div>
      </Card>

      <Card title="Actions" className="mt-2">
        <div className="flex  gap-3 ">
          <TopUpUser id={id} onSuccess={getData} />
          <BalanceDedcut id={id} onSuccess={getData} text={"Dedcut Balance"} />

          {/* <div >
<Typography.Text>Active </Typography.Text>
   <Switch/>
</div> */}
        </div>
      </Card>

{kyc&&<Card title="Kyc Details">

<div className="flex items-center justify-between">
            <Typography.Text>Name</Typography.Text>
            <Typography.Text>
            {kyc?.doc_name}
            </Typography.Text>
          </div>
<div className="flex items-center justify-between">
            <Typography.Text>Document Number</Typography.Text>
            <Typography.Text>
            {kyc?.doc_number}
            </Typography.Text>
          </div>

<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4" >
  <Image src={kyc?.doc_photo} alt="front"  className="rounded max-h-[200px]  object-fill"/>
  <Image src={kyc?.doc_photo_back} alt="back"  className="rounded max-h-[200px] object-fill" />

</div>

</Card>}
      <WalletHistory id={id} />
    </>
  );
}

export default UserOverView;
