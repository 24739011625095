import { Button, Card, Form, Input, Typography, message } from "antd";
import React, { useContext, useState } from "react";
import { post } from "../../helper/api";
import AppContext from "../../context/context";

function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getUser } = useContext(AppContext);

  const onSubmit = async (vals) => {
    setLoading(true);
    const res = await post("/auth/login", vals);
    if (res?.success) {
      message.success(res?.message);
      localStorage.setItem("ADMIN_TOKEN", res?.token);
      window.location.reload();
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };
  return (
    <div className="w-full h-screen flex justify-center bg-slate-50 p-2">
      <div className="w-full max-w-[500px] mt-[100px]">
        <div className="flex  flex-col items-center p-4">
          <Typography.Text className="font-bold text-sm">
            Welcome to
          </Typography.Text>
          <Typography.Text className="font-bold text-3xl">
            BabaLudo Admin
          </Typography.Text>
        </div>
        <Card title="Login Here">
          <Form layout="vertical" onFinish={onSubmit}>
            <Form.Item
              label="UserId"
              name={"mobile"}
              rules={[{ required: true, message: "Enter user id" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name={"password"}
              rules={[{ required: true }]}
            >
              <Input type="password" />
            </Form.Item>
            <Button
              className="btn-primary"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default Login;
