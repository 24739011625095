import { Skeleton, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function DataCard({title,value,loading,route}) {
  const navigate = useNavigate()
 
  return (
    <div className='bg-white shadow rounded-lg flex flex-col min-w-fit' onClick={()=>route?navigate(route):null}>
        <Typography.Text className=' bg-PRIMARY shadow font-bold text-white rounded text-center'>{title}</Typography.Text>


        <div className='flex items-center justify-center flex-1 p-4'>
        {loading?<Skeleton.Input active title={false}/>:
            <Typography.Text className='font-bold text-xl'>{value}</Typography.Text>}
        </div>


    </div>
  )
}

export default DataCard