import { Button, Card, Col, Modal, Row, Switch, Table, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Flex } from "antd";
import { IoIosAddCircle } from "react-icons/io";
import CreateGame from './CreateGame';
import { get, put } from '../../helper/api';
import { getTitle } from '../../helper/comp';
import EditGame from './EditGame';





const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Min',
      dataIndex: 'min',
      key: 'age',
    },
    {
      title: 'Max',
      dataIndex: 'max',
      key: 'max',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
    },
  ];

function Games() {
const [open,setOpen]=useState(false)
const [games,setGames]=useState()
const [editModal,setEditModal]=useState(false)
const [toBeEdit,setToBeEdit]=useState()


const onUpdate = async(id,data)=>{
  const res = await put(`/games/${id}`,data)
  if(res?.success){
    message.success(res?.message)
    getGames()
  }

}

const onEditClick=(id)=>{
  setEditModal(true)
  setToBeEdit(id)
 
}

const getGames=async()=>{
    const res = await get("/games")
    if(res?.success){
        const rows = res?.games?.map((g)=>{
            return{
                name:g?.title,
                min:g?.min,
                max:g?.max,
                active:<Switch defaultChecked={g?.active} onChange={()=>onUpdate(g?._id,{active:!g?.active})}/>,
                action:<Button onClick={()=>onEditClick(g?._id)}>Edit</Button>
            }
        })
        setGames(rows)
    }
}

useEffect(()=>{
getGames()
},[])

  return (
<>
<Modal open={open} onCancel={()=>setOpen(false)} title="Add New Game" footer={null}>
   <CreateGame setOpen={setOpen}/>
</Modal>
<Modal open={editModal} onCancel={()=>setEditModal(false)} title="Edit Game" footer={null}>
<EditGame setOpen={setEditModal} id={toBeEdit}/>
</Modal>
 <Card title={getTitle("Games",<IoIosAddCircle size={20} onClick={()=>setOpen(true)}/>)}>
    <div className='overflow-auto'>
<Table columns={columns} dataSource={games||[]}/>
    </div>
 </Card>
 </>
  )
}

export default Games