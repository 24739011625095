import { Button, Card, Table, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { getTitle } from '../../helper/comp'
import { get } from '../../helper/api'
import { Link } from 'react-router-dom'
import { TXN_TYPES } from '../../helper/constants'

const columns =[
    {title:"Id",dataIndex:"txnId",key:"txnid"},
    {title:"Label",dataIndex:"label",key:"label"},
    {title:"Txn For",dataIndex:"txnFor",key:"txnFor"},
    {title:"Cash",dataIndex:"cash",key:"cash"},
    {title:"Win",dataIndex:"win",key:"win"},
    {title:"Amount",dataIndex:"amount",key:"amount"},
    {title:"Oprator",dataIndex:"oprator",key:"oprator"},
    {title:"Closing",dataIndex:"closing",key:"closing"},
    // {title:"Time",dataIndex:"time",key:"time"},

]

function WalletHistory({id}) {
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState()
    const [page,setPage]=useState(1)
    const [limit,setLimit]=useState(10)
    const [total,setTotal]=useState(0)

    const getData=async()=>{
setLoading(true)
        const res = await get(`/users/wallet-history/${id}?p=${page}&l=${limit}`)
        if(res?.success){
            setData(res?.history?.map((w)=>{
                return {
                    ...w,
                    txnId:w?.txn_type===TXN_TYPES.battle?<Link to={`/battles/${w?.txnId}`} className='text-blue-500'>{w?.txnId}</Link>:w?.txnId
                }
            }))
            setTotal(res?.total)
            
        }else{
            message.error(res?.message)
        }
setLoading(false)
    }

    useEffect(()=>{
       
getData()
    },[page])

    
  return (
<Card title={getTitle("Wallet History",<Button onClick={getData} loading={loading}>Get</Button>)} className='mt-2'>
    <div className='w-full overflow-x-auto'>
<Table columns={columns} dataSource={data} loading={loading} pagination={{total:total,current:page,pageSize:limit,onChange:(e)=>setPage(e)}}/>
    </div>

</Card>
  )
}

export default WalletHistory