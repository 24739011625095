import { Button, Card, Image, Table, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { KYC_STATUS } from "../../helper/constants";
import { get, put } from "../../helper/api";
import { Link } from "react-router-dom";

const tabItems = [
  {
    key: KYC_STATUS.pending,
    label: (
      <Typography.Text className="capitalize">
        {KYC_STATUS.pending}
      </Typography.Text>
    ),
  },
  {
    key: KYC_STATUS.approved,
    label: (
      <Typography.Text className="capitalize">
        {KYC_STATUS.approved}
      </Typography.Text>
    ),
  },
  {
    key: KYC_STATUS.declined,
    label: (
      <Typography.Text className="capitalize">
        {KYC_STATUS.declined}
      </Typography.Text>
    ),
  },
];

function KycRequests() {
  const [tab, setTab] = useState(KYC_STATUS.pending);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const columns = [
    { title: "User", key: "user", dataIndex: "user" },
    { title: "Name", key: "name", dataIndex: "doc_name" },
    { title: "Document Number", key: "doc_number", dataIndex: "doc_number" },
    { title: "Front", key: "front", dataIndex: "doc_photo" },
    { title: "Back", key: "back", dataIndex: "doc_photo_back" },
    { title: "Status", key: "status", dataIndex: "status" },
    ...(tab === KYC_STATUS.pending
      ? [{ title: "Action", key: "action", dataIndex: "action" }]
      : []),
  ];


  const onUpdate =async(id,status)=>{
    message.open({
        type:"loading",
        
        content:"Updating Status..",
    key:id
    })

    const res = await put(`/kyc/update-status/${id}`,{status})
    if(res?.success){
        getData()
        message.open({
            type:"success",
            duration:2,
            content:res?.message,
            key:id
        })
    }else{
        message.open({
            type:"error",
            duration:2,
            content:res?.message,
            key:id
        })
    }

  }
  const getData = async () => {
    setLoading(true);
    const res = await get(`/kyc?status=${tab}`);
    if (res?.success) {
      setData(
        res?.kycs?.map((k) => {
          return {
            ...k,
            user:<Link to={`/users/${k?.user?._id}`}>{k?.user?.key_id}</Link>,
            doc_photo: (
          
                <Image
                  src={k?.doc_photo}
                  alt="docphoto"
                  className="w-[100px] h-[50px] rounded object-cover"
                  width={"100px"}
                  height={"50px"}
                />
          
            ),
            doc_photo_back: (
              <Image
              src={k?.doc_photo_back}
              alt="docphoto"
              className="w-[100px] h-[50px] rounded object-cover"
              width={"100px"}
              height={"50px"}
            />
       
            ),
            action: (
              <div className="flex flex-col gap-2">
                <Button className="bg-green-500 text-white" onClick={()=>onUpdate(k?._id,KYC_STATUS.approved)}>Approve</Button>
                <Button className="bg-red-500 text-white" onClick={()=>onUpdate(k?._id,KYC_STATUS.declined)}>Decline</Button>
              </div>
            ),
          };
        })
      );
    }
    setLoading(false);
  };


  useEffect(() => {
    getData();
  }, [tab]);

  return (
    <>
      <Card
        title="Kyc"
        tabList={tabItems}
        onTabChange={(e) => setTab(e)}
        activeTabKey={tab}
      >
        <div className="overflow-x-auto">

        <Table dataSource={data} columns={columns} />
        </div>
      </Card>
    </>
  );
}

export default KycRequests;
