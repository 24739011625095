import React, { useEffect, useState } from 'react'
import { WITHDRAWAL_STATUS } from '../../helper/constants'
import { Button, Card, Table, Typography } from 'antd'
import { get } from '../../helper/api'
import { Link } from 'react-router-dom'
import moment from 'moment'


const tabList = [
    {label:"Pending",key:WITHDRAWAL_STATUS.pending},
    {label:"Approved",key:WITHDRAWAL_STATUS.approved},
    {label:"Declined",key:WITHDRAWAL_STATUS.declined},
]
function Withdrawals() {
    const [status,setStatus]=useState(WITHDRAWAL_STATUS.pending)
    const [data,setData]=useState()
    const [loading,setLoading]=useState(false)


    const columns=[
        {title:"User",dataIndex:"user",key:"user"},
        {title:"Amount",dataIndex:"amount",key:"amount"},
        {title:"Mode",dataIndex:"mode",key:"mode"},
        {title:"DateTime",dataIndex:"datetime",key:"datetime"},
        ...(status===WITHDRAWAL_STATUS.pending?[{title:"Actions",dataIndex:"action",key:"action"}]:[]),
    ]
    const getData = async()=>{
        setLoading(true)
        let res;

        res = await get(`/withdraw?status=${status}`)
        if(res?.success){
            setData(res?.withdraws?.map((w)=>{
                return {
                    ...w,
                    user:<Link to={`/users/${w?.user?._id}`}>{w?.user?.name}</Link>,
                    datetime:<Typography.Text className='text-xs'>{moment(w?.createdAt).format("DD-MM-YYYY, hh:mm a")}</Typography.Text>,
                    action:<Button><Link to={`/withdraws/${w?._id}`}>View</Link></Button>
                }
            }))
        }
        setLoading(false)

    }
    useEffect(()=>{
getData()
    },[status])
  return (
    <Card tabList={tabList} activeTabKey={status} onTabChange={(e)=>setStatus(e)}>

        <div className='overflow-x-auto'>
             <Table columns={columns} dataSource={data} loading={loading}/>
        </div>

    </Card>
  )
}

export default Withdrawals