import React, { useEffect, useState } from "react";
import AppContext from "./context";
import { get } from "../helper/api";

function AppStates({ children }) {
  const [sideBar, setSideBar] = useState(false);
  const [user, setUser] = useState();
  const [AppLoading, setAppLoading] = useState(true);

  const getUser = async () => {
    setAppLoading(true);
    const res = await get("/auth");
    if (res?.success) {
      setUser(res?.user);
    }
    setAppLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AppContext.Provider
      value={{ sideBar, setSideBar, user, AppLoading, getUser }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppStates;
