import { Button, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { put } from "../../helper/api";

function TopUpUser({ id, text, onSuccess }) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();

  const onSubmit = async (vals) => {
    setLoading(true);
    const res = await put(`/users/top-up/${id}`, vals);
    if (res?.success) {
      message.success(res?.message);
      setOpen(false);
      form.resetFields()
      if (onSuccess) {
        onSuccess();
      }
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label="Amount"
            name={"amount"}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <div className="flex items-center w-full justify-end gap-2">
            {!loading && <Button onClick={() => setOpen(false)}>Cancel</Button>}

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Button onClick={() => setOpen(true)}>{text || "TopUp"}</Button>
    </>
  );
}

export default TopUpUser;
