import { Button, Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import { USER_STATUS } from "../../helper/constants";
import { get } from "../../helper/api";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { getTitle } from "../../helper/comp";
import Search from "antd/es/input/Search";

const tabItems = [
  { label: "Active", key: USER_STATUS.active },
  { label: "Blocked", key: USER_STATUS.blocked },
  { label: "Unverified", key: USER_STATUS.unverified },
];
const columns = [
  { title: "Id", dataIndex: "key_id", key: "key_id" },
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Mobile", dataIndex: "mobile", key: "mobile" },
  { title: "Cash", dataIndex: "cash", key: "cash" },
  { title: "Win", dataIndex: "win", key: "win" },
  { title: "Actions", dataIndex: "action", key: "action" },
];

function Users() {
  const [data, setData] = useState();
  const [tab, setTab] = useState(USER_STATUS.active);
  const [search, setSearch] = useState("");
  
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onView = (id) => {
    navigate(`/users/${id}`);
  };

  const getData = async () => {
    setLoading(true);
    let res;
    if (search !== "" || search) {
      res = await get(`/users?search=${search}&limit=${limit}&page=${page}`);
    } else if(tab!=USER_STATUS.unverified) {
      res = await get(`/users?status=${tab}&verified=true&limit=${limit}&page=${page}`);
    }else{
      res = await get(`/users?verified=false&limit=${limit}&page=${page}`);
    }
    if (res?.success) {
      setTotalData(res?.totalCounts)
      setData(
        res?.users?.map((u) => {
          return {
            ...u,
            action: <Button onClick={()=>onView(u?._id)}>View</Button>,
          };
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [tab,page,limit]);
  useEffect(()=>{
  if(data&&!loading){  const timeout = setTimeout(()=>{
     
        getData()
      
    },1000)
    return ()=>clearTimeout(timeout)
}
  },[search])
  if (!data) {
    return <Loader />;
  }
  return (
    <>
      <Card
        title={getTitle("Users")}
        tabList={search?[]:tabItems}
        onTabChange={(e) => setTab(e)}
        activeTabKey={tab}
      >
        <Search  onChange={(e)=>setSearch(e.target.value)} placeholder="Search user"/>
        <div className="w-full overflow-x-auto mt-2">
          <Table columns={columns} dataSource={data} loading={loading} pagination={{onChange:(e)=>setPage(e),pageSize:limit,total:totalData}} />
        </div>
      </Card>
    </>
  );
}

export default Users;
