import logo from './logo.svg';
import './App.css';
import AppLayout from './layouts/AppLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AUTH_ROUTES, ROUTES } from './Routes';
import { useContext } from 'react';
import AppContext from './context/context';
import Loader from './components/Loader';

function App() {
  const {AppLoading,user}=useContext(AppContext)
  
  if(AppLoading){
    return (
      <div className='h-screen'>
        <Loader/>
      </div>
    )
  }
  if(!AppLoading&&!user){
    return (
      <Routes>
      {AUTH_ROUTES?.map((r,i)=>{
        return <Route path={r?.path} Component={r?.component}/>
      })}
      <Route path='*' element={<Navigate to={"/auth"}/>}/>

    </Routes>
    )
  }

  return (
  <AppLayout>
    <Routes>
      {ROUTES?.map((r,i)=>{
        return <Route path={r?.path} Component={r?.component}/>
      })}
      <Route path='*' element={<Navigate to={"/dashboard"}/>}/>

    </Routes>

  </AppLayout>
  );
}

export default App;
