export const BATTLE_STATUS = {
  open: "open",
  requested: "requested",
  running: "running",
  responed: "responed",
  complete: "complete",
  pending: "pending",
  cancelled: "cancelled",
  timeout: "timeout",
  semi_pending: "semi_pending",
};

export const KYC_STATUS = {
  pending: "pending",
  approved: "approved",
  declined: "declined",
};

export const USER_STATUS = {
  active: "active",
  blocked: "blocked",
  unverified: "unverified",
};

export const FUND_STATUS = {
  pending: "pending",
  success: "success",
  failed: "failed",
};
export const WITHDRAWAL_STATUS = {
  pending: "pending",
  approved: "approved",
  declined: "declined",
};

export const TXN_TYPES = {
  deposit: "deposit",
  deduct: "deduct",
  refund: "refund",
  cash: "cash",
  win: "win",
  lose: "lose",
  battle: "battle",
  refer: "refer",
  withdraw: "withdraw",
  penalty: "penalty",
};
