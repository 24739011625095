import Login from "./pages/auth/Login";
import Battles from "./pages/battles/Battles";
import OverView from "./pages/battles/OverView";
import Dashboard from "./pages/dashboard/Dashboard";
import FundRequests from "./pages/fund-requests/FundRequests";
import KycRequests from "./pages/kyc/KycRequests";
import Settings from "./pages/settings/Settings";
import UserOverView from "./pages/users/UserOverView";
import Users from "./pages/users/Users";
import WithdrawalView from "./pages/withdrawals/WithdrawalView";
import Withdrawals from "./pages/withdrawals/Withdrawals";

const { default: Games } = require("./pages/games/Games");





export const ROUTES = [
    {
        name:"Dashboard",
        key:"dashboard",
        icon:"",
        path:"/dashboard",
        show:true,
        component:Dashboard
    },
    {
        name:"Users",
        key:"users",
        icon:"",
        path:"/users",
        show:true,
        component:Users
    },
    {
        name:"Users",
        key:"users",
        icon:"",
        path:"/users/:id",
        show:false,
        component:UserOverView
    },
    {
        name:"Battles",
        key:"battles",
        icon:"",
        path:"/battles",
        show:true,
        component:Battles
    },
    {
        name:"Battle Overview",
        key:"overview",
        icon:"",
        path:"/battles/:id",
        show:false,
        component:OverView
    },
    {
        name:"Kyc",
        key:"kyc",
        icon:"",
        path:"/kyc",
        show:true,
        component:KycRequests
    },
    {
        name:"Funds",
        key:"funds",
        icon:"",
        path:"/funds",
        show:true,
        component:FundRequests
    },
    {
        name:"Withdraws",
        key:"withdraws",
        icon:"",
        path:"/withdraws",
        show:true,
        component:Withdrawals
    },
    {
        name:"Withdraws",
        key:"withdraws",
        icon:"",
        path:"/withdraws/:wid",
        show:false,
        component:WithdrawalView
    },
    {
        name:"Games",
        key:"games",
        icon:"",
        path:"/games",
        show:true,
        component:Games
    },
    {
        name:"Settings",
        key:"settings",
        icon:"",
        path:"/settings",
        show:true,
        component:Settings
    },
]


export const AUTH_ROUTES=[
    {
        name:"Login",
        key:"auth",
        icon:"",
        path:"/auth",
        show:false,
        component:Login
    },
]